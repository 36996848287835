'use client';

import React, { useEffect } from 'react';
import Button from '@/components/Button';
import { Link } from '@/Hooks/useReactTransitionProgress';
import { logger } from '@/Util/globals';
import GridContainer from '@/Layouts/GridContainer';

export default function Error({
  error,
  reset
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    logger('error')(error);
  }, [error]);

  return (
    <GridContainer>
      <h2>There was an error during loading the page...</h2>
      <Button color="primary" className="mb-xs-3" onClick={() => reset()}>
        Please try again
      </Button>
      <div>
        <a href="/">or go back to Home</a>
      </div>
    </GridContainer>
  );
}
